import React, { useState } from 'react';
// import PropTypes from 'prop-types';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const ContactForm = ( props ) => {
    const [ isSubmitted, setSubmitted ] = useState( false );

    function handleSubmit( e ){
        e.preventDefault();
        const data = {};
        
        new FormData(e.target).forEach((value, key) => { data[key] = value });

        fetch( e.target.action , {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode( data )
        })
        .then( response => {
            if ( response.status !== 200 && response.status !== 201 ) {
                console.error(`Looks like there was a problem. Status Code: ${response.status}`);
                return;
            }

            setSubmitted( true );
        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    }

    return (
        <div className="contact">
            <h2 className="contact__title">{props.title}</h2>
            <p className="contact__desc">{props.description}</p>

            {
                !isSubmitted ?
                <form className="contact__form" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={ handleSubmit }>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" name="name" required />
                    </div>
        
                    <div className="form-group">
                        <label htmlFor="name">Job title</label>
                        <input className="form-control" type="text" id="job-title" name="job-title" required />
                    </div>
        
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input className="form-control" type="email" id="email" name="email" required/>
                    </div>
        
                    <div className="form-group">
                        <label htmlFor="telephone">Telephone (optional)</label>
                        <input className="form-control" type="tel" id="telephone" name="telephone" />
                    </div>
        
                    <input name="bot-field" type="hidden"/>
                    <input name="form-name" type="hidden" value="contact"/>
        
                    <button className="button button--primary button--block">Request consultation<span className="icon-arrow-right"></span></button>
                </form>
        
                :
        
                <p className="contact__msg-success">
                    <strong>{props.successMsg}</strong>
                </p>
            }
        </div>
        
    )
}

export default ContactForm;