import React from 'react'
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';
import SocialShare from '../components/SocialShare';
import useSiteMetadata from '../components/SiteMetadata';

export const ServiceTemplate = ({
  content,
  contentComponent,
  image,
  title,
  helmet,
  pdfDownload,
  eventLink,
  contactForm,
  isFormVisible,
  href
  }) => {
  const PageContent = contentComponent || Content
  
  return (
    <>
      {helmet || ''}

      <Parallax bgImage={image} strength={500}>
        <div className="cover-container">
            <Hero className="cover">
                <h1>{title}</h1>
            </Hero>
        </div>
      </Parallax>

      <div className="section">
          <div className="section__inner">
              <div className="container">

                  <div className="col-md-7">
                      <PageContent className="content" content={content}/>

                      {pdfDownload &&
                        <a href={pdfDownload.publicURL} className="button">Download as PDF<span className="icon-download"></span></a>
                      }
                      
                      <div className="visible-md visible-lg">
                        <SocialShare url={href} title={title}/>
                      </div>
                  </div>

                  <div className="col-md-4 col-md-offset-1">
                      { eventLink &&
                        <div className="block">
                          <a href={eventLink} className="button button--block" target="_blank" rel="noopener noreferrer">
                            View events<span className="icon-arrow-right"></span>
                          </a>    
                        </div>   
                      }

                      { isFormVisible &&
                        <ContactForm {...contactForm}/>           
                      }
                  </div>

                  <div className="visible-xs visible-sm">
                    <SocialShare url={href} title={title}/>
                  </div>

              </div>
          </div>
      </div>
    </>
  )
}

ServiceTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Service = ({ data, location }) => {
  const { page, footerData, headerData, contactForm } = data;
  const { title } = useSiteMetadata();

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <ServiceTemplate
        title={page.frontmatter.title}
        content={page.html}
        contentComponent={HTMLContent}
        image={page.frontmatter.image.publicURL}
        href={location.href}
        pdfDownload={page.frontmatter.pdfDownload}
        eventLink={page.frontmatter.eventLink}
        contactForm={contactForm.frontmatter}
        isFormVisible={page.frontmatter.isFormVisible}
        helmet={
          <Helmet titleTemplate={`%s | Services | ${title}`}>
            <title>{`${page.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

Service.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const query = graphql`
  query ServiceByID($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        testimonials {
          html
          frontmatter {
            name
            citation
          }
        }
      }
      frontmatter {
        title
        image {
          publicURL
        }
        pdfDownload {
          publicURL
        }
        eventLink
        isFormVisible
      }
    }
    contactForm: markdownRemark(frontmatter: { templateKey: { eq: "contact-form" } }) {
        id
        frontmatter {
          title
          description
          successMsg
        }
    }
    ...LayoutFragment
  }
`

export default Service